<template>
  <div class="unselect" @click="doCopy">
    <img src="/static/icon/ic_share_circle.svg">
    <div class="size-12 sub1 text-center margin-top-8">추천인코드 복사</div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueClipboard from 'vue-clipboard2'
  Vue.use(VueClipboard);

  export default {
    name: "BtnUrlCopy",
    props: {
      url: {
        type: String,
        default: location.href
      }
    },

    computed: {
    },
    methods: {
      doCopy() {
        this.$copyText(this.url).then(() => {
          this.toast('추천인 코드가 복사되었습니다.')
          this.$emit('onShare');
        }, () => {
          this.toast('URL 복사에 실패했습니다.')
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .ellipsis
    width 60px
    height 60px
    display flex
    align-items center
    justify-content center
    border-radius 100%
</style>
