<template>
  <div class="share-container">
    <sweet-modal ref="modal" :hide-close-button="true" :enableMobileFullscreen="false"
                 overlay-theme="dark">
      <div class="flex-between">
        <div class="body2 main">공유하기</div>
        <i @click="$refs.modal.close()" class="unselect material-icons" style="font-size: 24px">close</i>
      </div>
      <div class="text-center margin-top-20 main">
        <div class="subtitle5">추천인 코드</div>
        <div class="h6">{{ user.recommend_code }}</div>
      </div>
      <div class="grid-box" style="margin-top:32px">
        <btn-share-kakao :shareData="sharedData" @onShare="$refs.modal.close()"></btn-share-kakao>
        <btn-share-band :shareData="sharedData" @onShare="$refs.modal.close()"></btn-share-band>
        <btn-share-facebook :shareData="sharedData" @onShare="$refs.modal.close()"></btn-share-facebook>
        <btn-url-copy :url="sharedData.link.split('recm=')[1]" @onShare="$refs.modal.close()"></btn-url-copy>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';
  import BtnUrlCopy from "./BtnUrlCopy";
  import BtnShareBand from "./BtnShareBand";
  import BtnShareKakao from "./BtnShareKakao";
  import BtnShareFacebook from "./BtnShareFacebook";
  export default {
    name: "Share",
    components: {
      BtnShareFacebook,
      BtnShareKakao,
      BtnShareBand,
      BtnUrlCopy,

      SweetModal
    },
    props: {
      sharedData: {
        type: Object,
        default: function() {
          return {
            name: '',
            desc: '',
            img: '',
            link: '',
          }
        }
      }
    },
    methods: {
      open() {
        this.$refs.modal.open();
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .grid-box
    display grid
    grid-template-columns repeat(4, 1fr)
</style>
<style lang="stylus">
  .share-container
    .sweet-modal
      max-width 500px
    .sweet-modal.is-alert .sweet-content
      padding-top 24px
      padding-bottom 24px

  @media (max-width:500px)
    .share-container
      .sweet-modal-overlay
        z-index 9999999999
      .sweet-modal
        top initial
        width 100%
        border-bottom-left-radius 0 !important
        border-bottom-right-radius 0 !important
        bottom 0
        transform translateX(-50%) !important
      .sweet-modal.is-alert .sweet-content
        padding 12px 16px 24px 16px !important
</style>
