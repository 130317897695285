<template>
<div @click="clickShare" class="unselect">
  <img src="/static/icon/ic_facebook_circle.svg">
  <div class="size-12 sub1 text-center margin-top-8">페이스북</div>
</div>
</template>

<script>
  export default {
    name: "BtnShareFacebook",

    props: {
      shareData: {
        type: Object,
        required: false
      }
    },

    created() {
      window.fbAsyncInit = () => {
        // eslint-disable-next-line no-undef
        FB.init({
          appId: '5393583087388491',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.1'
        });
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/ko_KR/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },

    methods: {
      clickShare() {
        // eslint-disable-next-line no-undef
        FB.ui({
          method: 'share',
          mobile_iframe: true,
          href: this.shareData.link,
        }, function () {});
        this.$emit('onShare');
      }
    },
    computed: {
    }
  }
</script>

<style lang="stylus" scoped>

</style>
